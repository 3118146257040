<div class="dialog">
	<div class="dialog__header-actions">
		<button
			class="dialog__close-button"
			type="button"
			(click)="closeDialogWithResult(false)"
		>
			<mat-icon class="dialog__close-icon">close</mat-icon>
		</button>
	</div>
	<div class="dialog__content">
		<header class="dialog__header">
			<h1 class="typography-header">{{ title$ | async }}</h1>
			<ng-content select="[headerActions]" />
		</header>

		<div class="dialog__body">
			<p class="dialog__text typography-body">
				{{ dialogData.message }}
			</p>

			<ng-content />
		</div>
	</div>
</div>
