import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { DialogData, DialogSize } from 'projects/admin-web/src/app/features/shared/components/dialog/dialog.component';

import { filterNull } from '../utils/rxjs/filter-null';

const MAP_DIALOG_SIZE_PX: Record<DialogSize, number> = {
	small: 300,
	normal: 480,
	big: 936,
	large: 1300,
};

/** Dialogs service. */
@Injectable({
	providedIn: 'root',
})
export class DialogsService {

	private readonly dialog = inject(MatDialog);

	/**
	 * Opens dialog.
	 * @param component Component for which dialog should be opened.
	 * @param config Dialog config.
	 * @param dialogData Dialog data.
	 */
	public openDialogWith<TComponent, TData extends DialogData, TResult>(
		component: ComponentType<TComponent>,
		dialogData?: TData,
		config?: MatDialogConfig<TData>,
	): Observable<TResult> {
		return this.dialog.open<TComponent, TData, TResult>(component, {
			data: dialogData,
			panelClass: 'custom-dialog',
			width: `${MAP_DIALOG_SIZE_PX[dialogData?.size ?? 'normal']}px`,
			...config,
		}).afterClosed()
			.pipe(filterNull());
	}
}
