import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject } from 'rxjs';

/** Dialog size. */
export type DialogSize = 'large' | 'big' | 'normal' | 'small';

/** Dialog data. */
export type DialogData = {

	/** Title. */
	readonly title: string;

	/** Message. */
	readonly message?: string;

	/** Size. */
	readonly size?: DialogSize;
};

/** Dialog component. */
@Component({
	selector: 'ygmaw-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatIconModule, CommonModule],
})

export class DialogComponent<T extends DialogData = DialogData> {
	/** Dialog title. */
	@Input()
	public set title(newTitle: string | null) {
		if (newTitle == null) {
			return;
		}
		this.title$.next(newTitle);
	}

	/** Dialog ref. */
	protected readonly dialogRef = inject(MatDialogRef<DialogComponent<T>>);

	/** Dialog ref. */
	protected readonly dialogData = inject<T>(MAT_DIALOG_DATA);

	/** Dialog title. */
	protected readonly title$ = new BehaviorSubject(this.dialogData.title);

	/** Handles click on cancel button. */
	protected onCancelButtonClick(): void {
		this.dialogRef.close(false);
	}

	/**
	 * Close dialog with result value.
	 * @param result False means user closed dialog without any action. True means user did the action successfully.
	 */
	protected closeDialogWithResult(result: boolean): void {
		this.dialogRef.close(result);
	}
}
